import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { mediaQueries } from "../utils/presets"

const SelectLanguage = props => {
  const locales = props.langs.map(lang => (
    <Link
      to={lang.link}
      key={lang.langKey}
      css={{
        [mediaQueries.md]: {
          color: "white",
        },
        display: `inline-flex`,
        padding: `7px 17px`,
        background: lang.selected ? `rgba(255, 255, 255, 0.2);` : `none`,
      }}
    >
      <li selected={lang.selected}>{lang.langKey}</li>
    </Link>
  ))

  return (
    <section>
      <header
        css={{
          color: "white",
        }}
      />
      <ul
        className="selectedLanguage"
        css={{
          border: `1px solid`,
          borderRadius: `4px`,
        }}
      >
        {locales}
      </ul>
    </section>
  )
}

SelectLanguage.propTypes = {
  langs: PropTypes.array.isRequired,
}

export default SelectLanguage
