import React from "react"
/** @jsx jsx */
import { jsx } from "@emotion/core"

const Section = props => (
  <section className={props.class} css={props.css}>
    <div className={`section ${props.pb0 ? "pb-0" : ""}`}>
      <div className="container">{props.children}</div>
    </div>
  </section>
)

export default Section
