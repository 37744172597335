import React, { Fragment, useState } from "react"
import SelectLanguage from "../SelectLanguage"
import lang from "../../data/languages"
import { Link, graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "react-intl"
import NBLogo from "../../images/logoNBG.svg"

/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { mediaQueries, colors } from "../../utils/presets"
import Img from "gatsby-image"

import * as PropTypes from "prop-types"

const Header = props => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      iconDeals: file(name: { eq: "icon-deals" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      us: allContentfulTravelType(
        sort: { order: ASC, fields: order }
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            id
            order
            slug
            typeTitle
            typeShortDescription
            typePhoto {
              fixed(width: 40, height: 40) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
      es: allContentfulTravelType(
        sort: { order: ASC, fields: order }
        filter: { node_locale: { eq: "es" } }
      ) {
        edges {
          node {
            id
            order
            slug
            typeTitle
            typeShortDescription
            typePhoto {
              fixed(width: 40, height: 40) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
    }
  `)

  const currentLang =
    props.currentLang === lang.defaultLangKey ? "" : `/${props.currentLang}`

  const TravelsTypeItems =
    props.currentLang === "es"
      ? data.es.edges.map(i => i.node)
      : data.us.edges.map(i => i.node)

  const DestinationsItem = TravelsTypeItems.find(item => {
    return item.slug == `destinations`
  })

  const [isBurgerActive, SetIsBurgerActive] = useState(false)
  const [isDropdownActive, SetIsDropdownActive] = useState(false)
  const [isServicesActive, SetIsServicesActive] = useState(false)

  const ToggleDropdown = () => {
    SetIsDropdownActive(!isDropdownActive)
  }
  const ToggleDropdownServices = () => {
    SetIsServicesActive(!isServicesActive)
  }

  return (
    <>
      <div className="container">
        <nav
          className="navbar is-transparent cl-effect-13"
          css={{
            position: `absolute`,
            width: `100%`,
            background: `transparent`,
            top: 25,
          }}
        >
          <div
            className="navbar-brand"
            css={{
              alignItems: `center`,
            }}
          >
            <Link
              className="navbar-item "
              to={`${currentLang}`}
              css={{
                color: "white",
                textDecoration: "none",
              }}
            >
              <img
                css={{
                  width: 80,
                  [mediaQueries.lg]: {
                    minHeight: `4rem`,
                    width: 130,
                  },
                }}
                src={NBLogo}
                title={data.site.siteMetadata.title}
              />
            </Link>
            <div
              css={{
                marginLeft: `auto`,
                marginRight: 15,
                cursor: `pointer`,
                [mediaQueries.lg]: {
                  display: `none`,
                },
              }}
              onClick={() => SetIsBurgerActive(!isBurgerActive)}
            >
              <div className=" hamburger" />
            </div>
          </div>

          <div
            id="main-navbar"
            className={`navbar-menu main-navbar ${
              isBurgerActive ? "is-active" : ""
            }`}
          >
            <div className="navbar-start">
              <Link
                className="navbar-item navbar-menu-item"
                activeClassName="navbar-item--active"
                to={`${currentLang}`}
              >
                <FormattedMessage id="Home" />
              </Link>
              <Link
                className="navbar-item navbar-menu-item"
                activeClassName="navbar-item--active"
                to={`${currentLang}/about-us`}
              >
                <FormattedMessage id="AboutUs" />
              </Link>
              <div
                className="navbar-item has-dropdown is-hoverable is-cat"
                onClick={ToggleDropdown}
              >
                <a className="navbar-link">
                  <FormattedMessage id="Tours" />
                </a>

                <div
                  className={`navbar-dropdown is-boxed ${
                    isDropdownActive ? "is-active" : ""
                  }`}
                >
                  <Link
                    css={{ marginBottom: 5 }}
                    className="navbar-item"
                    to={`${currentLang}/deals`}
                  >
                    <Img
                      css={{
                        marginRight: 15,
                      }}
                      alt={"deals"}
                      fixed={data.iconDeals.childImageSharp.fixed}
                    />{" "}
                    <div>
                      <strong>
                        {" "}
                        <FormattedMessage id="ViewDeals" />
                      </strong>
                      <br />
                      <small>
                        {" "}
                        <FormattedMessage id="DealsPhrase" />
                      </small>
                    </div>
                  </Link>

                  {TravelsTypeItems.filter(x => {
                    return x.slug !== "destinations"
                  }).map(item => (
                    <Link
                      css={{ marginBottom: 5 }}
                      className="navbar-item"
                      key={item.id}
                      to={`${currentLang}/${item.slug}`}
                    >
                      <Img
                        css={{
                          marginRight: 15,
                        }}
                        alt={item.typeTitle}
                        fixed={item.typePhoto.fixed}
                      />{" "}
                      <div>
                        <strong>{item.typeTitle}</strong>
                        <br />
                        <small>{item.typeShortDescription}</small>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>

              <div
                className="navbar-item has-dropdown is-hoverable is-Services"
                onClick={ToggleDropdownServices}
              >
                <a className="navbar-link">
                  <FormattedMessage id="Services" />
                </a>

                <div
                  className={`navbar-dropdown is-boxed ${
                    isServicesActive ? "is-active" : ""
                  }`}
                >
                  <Link
                    css={{ marginBottom: 5 }}
                    className="navbar-item"
                    to={`${currentLang}/transportation`}
                  >
                    <FormattedMessage id="Transportation" />
                  </Link>

                  <Link
                    css={{ marginBottom: 5 }}
                    className="navbar-item"
                    to={`${currentLang}/events`}
                  >
                    <FormattedMessage id="Events" />
                  </Link>

                  <Link
                    css={{ marginBottom: 5 }}
                    className="navbar-item"
                    to={`${currentLang}/other-services`}
                  >
                    <FormattedMessage id="OtherServices" />
                  </Link>
                </div>
              </div>

              {/* <Link
                className="navbar-item navbar-menu-item"
                activeClassName="navbar-item--active"
                to={`${currentLang}/our-services`}
              >
                <FormattedMessage id="Services" />
              </Link> */}
              <Link
                className="navbar-item navbar-menu-item"
                activeClassName="navbar-item--active"
                to={`${currentLang}/${DestinationsItem.slug}`}
              >
                {DestinationsItem.typeTitle}
              </Link>
              {/* <Link
                className="navbar-item navbar-menu-item"
                activeClassName="navbar-item--active"
                to={`${currentLang}/transportation`}
              >
                <FormattedMessage id="Transportation" />
              </Link> */}
              <Link
                className="navbar-item navbar-menu-item"
                activeClassName="navbar-item--active"
                to={`${currentLang}/photo-gallery`}
              >
                <FormattedMessage id="Gallery" />
              </Link>
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <SelectLanguage langs={props.langs} />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

Header.propTypes = {
  currentLang: PropTypes.string.isRequired,
  langs: PropTypes.any.isRequired,
}

export default Header
