import React from "react"
import { FormattedMessage } from "react-intl"
import { animated, useSpring, config } from "react-spring"
import styled from "@emotion/styled"

import * as PropTypes from "prop-types"

const PrimaryTitle = ({ textId }) => {
  const titleProps = useSpring({
    config: config.stiff,
    from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
    to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
  })

  const PageTitle = styled(animated.h1)`
    marginbottom: 60;
  `
  return (
    <FormattedMessage id={textId}>
      {txt => (
        <PageTitle style={titleProps} className="primary-title">
          {txt}
        </PageTitle>
      )}
    </FormattedMessage>
  )
}

PrimaryTitle.prototype = {
  textId: PropTypes.string.isRequired,
}

export default PrimaryTitle
