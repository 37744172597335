module.exports = {
  selectLanguage: "Seleccionar Lenguaje",
  itinerario: "Itinerario",
  details: "Detalles",
  mainTitle:
    "Ejemplo de uso de Contentful como Fuente de datos para un sitio de GatsbyJs",
  popularDestination: "Destinos Populares",

  Alternatives: "Alternativas",
  WeKnow: "Conocemos",
  WeAreText:
    "Somos una empresa de servicio completo Tour Operador y gestión de destinos (DMC).",
  WeAreText2:
    "Ofrecemos tours en Nicaragua con tarifas competitivas y una amplia selección de productos, lo cual nos permite brindar un servicio de primera clase a nuestros clientes.",
  AboutSubText: "Somos mas que una Agencia de Viaje",

  ViewMore: "Ver más",

  //ModalForm
  SendRequest: "Solicitud en Línea",
  SendMessage: "Enviar Solicitud",
  PackageName: "Nombre del Paquete",
  EmailAddres: "Correo Eléctronico",
  Comments: "Comentarios Adicionales",

  //MenuItem
  Home: "Inicio",
  AboutUs: "Sobre Nosotros",
  Services: "Servicios",
  Guides: "Categorias",
  Transportation: "Transportes",
  Gallery: "Galería",
  ContactUs: "Contáctenos",
  FollowUs: "Encuentrenos en:",
  Deals: "Promociones",
  HotDeals: "En Oferta",
  Tours: "Excursiones",

  //Index
  ViewDeals: "Promociones",
  DealsPhrase: "Tenemos las mejores promociones de Nicaragua",
  From: "Desde",
  Now: "Ahora",
  EnjoyPhrase: "¡Descubra las atracciones más populares del país con nosotros!",
  viewAll: "Ver Todos los Destinos",
  WhyChooseUs: "¿Por qué elegir",
  WhyPhrase: "¡Viaja seguro con el respaldo que necesitas!",
  Assistance: "Asistencia 24/7",
  Departures: "Salidas garantizadas",
  Environmentally: "Somos una empresa responsable con el medio ambiente",
  Quality: "Calidad en nuestros servicios",
  Nofee: "Sin recargos por cambios de fecha",
  Handpicked: "Excursiones & experiencias elegidas",
  Recommends: "Recomienda",
  //footer<
  ProudMember: "Miembros de",
  Office: "Oficina",
  "24HourAttention": "Atención 24/Horas:",

  //About Page
  LeadingTour: "Tour operador líder en Nicaragua",
  WhoWeAre: "Quienes Somos",
  WhoWeAreContent:
    "<p>Nos sentimos orgullosos de presentarle a Nicaragua’s Best Guides. Nuestra empresa ofrece servicios completos en hospedaje, traslados, tours, así como la organización de congresos y viajes de incentivos (DMC). Nicaragua’s Best Guides se distingue por ofrecer un servicio profesional, de calidad y atención personalizada a nuestros clientes las 24 horas. Contamos con tarifas competitivas y una amplia selección de productos, lo cual nos permite brindar un servicio de primera clase a nuestros clientes.</p><p>Con licencia de operador turístico aprobado por el Instituto Nicaragüense de Turismo, nuestro nombre ha sido sinónimo de desarrollo e implementación de nuevos productos en el país, así como también de responsabilidad empresarial al apoyar programas de conservación y protección del medio ambiente impulsados por Rain Forest Alliance y el Fondo Natura.</p><p>Nuestra moderna flota de autobuses ofrece tours privados, así como servicios de transporte terrestre a las principales atracciones turísticas en el país. Nuestra oficina principal está ubicada en la ciudad colonial de Granada.</p>",
  HappyCustomers: "Clientes Satisfechos",
  ToursAndPackages: "Excursiones & Paquetes",
  ExperienceYears: "Años de Experiencia",

  OurMission: "Nuestra Misión",
  OurMissionTitle: "Somos una empresa de turismo receptivo que proporciona ",
  OurMissionDesc:
    "servicios turísticos integrales, que se distinguen en el mercado por su alta competitividad, rendimiento, calidad, excelencia y compromiso con el desarrollo sostenible del país. Apoyamos la innovación permanente, la formación y la incorporación de tecnología de punta para satisfacer a los turistas nacionales y extranjeros.",

  OurVision: "Nuestra Visión",
  OurVisionTitle:
    "Nos esforzamos por ser un operador turístico de servicio completo",
  OurVisionDesc:
    "de servicio completo que responde siempre a las tendencias del mercado, tiene reconocimiento nacional e internacional con personal altamente calificado. Tratamos de ser un operador que utiliza lo último en tecnología y que ofrece servicios innovadores, competitivos y de alta calidad que llenen las expectativas de nuestros clientes, dentro de un marco de turismo sostenible.",

  //Events Page
  Events: "Eventos",
  ServicesSubText: "Tenemos varios servicios para eventos a su disposición",
  ts_Weddings:
    " <p>Antes de ir al altar, usted tiene la última oportunidad de celebrar su despedida de soltero con sus amigos íntimos y familiares. ¿Por qué no celebrarlo a bordo de un transporte de Nicaragua´s Best Guides?</p><p>También podemos proporcionar entretenimiento y transporte confiable para cenas de ensayo, recepciones, etc. Si usted recibe a muchos de sus invitados de fuera de la ciudad para su boda, ¿qué mejor forma de llevarlo por la ciudad que en un transporte de Nicaragua´s Best Guides? Nuestro transporte único proporcionará un día aún más memorable para usted y sus invitados. Contáctenos para más información.</p>",
  ts_Events:
    " <p>¿Planifica de un lanzamiento de producto, reuniones laborales, salidas, sesión de entrenamiento, eventos de promoción, o una conferencia? Si tiene que trasladar y entretener de 10 a 500 personas, permítale a Nicaragua´s Best Guides alivierle la carga con nuestro servicio profesional. Dele a sus huéspedes, especialmente los que visitan del extranjero, la oportunidad de experimentar Nicaragua.</p><p>Si usted está organizando una convención podemos hacer arreglos para su grupo, como todo el transporte que puedan necesitar. Contáctenos para más información.</p>",
  ts_meetings:
    " <p>¿Planifica un cumpleaños especial, aniversario, o alguna tradición anual? Independientemente de la razón de tener a su familia o amigos reunidos, cuente con Nicaragua´s Best Guides para servicio de transporte y guía profesional y cortés, en cualquier parte de Nicaragua.</p><p>Si a usted y su familia o amigos le gustaría pasar el tiempo viajando a través de Nicaragua en conjunto, considere organizar una excursión  en grupo personalizada. También podemos coordinar alojamiento, atracciones, etc., para todo su grupo. Contáctenos para más información.</p>",
  ts_Colleges:
    "<p>Gire a Nicaragua´s Best Guides si su grupo necesita transporte escolar fiable y divertido entre los destinos dentro y fuera de Nicaragua. Nuestro departamento de ventas también organiza visitas turísticas educacionales de Nicaragua para su clase. Tenemos experiencia en proporcionar servicios de grupos para los equipos deportivos, viajes de grupo, clubes, viajes de campo y mucho más. Contáctenos para mayor información.</p>",
  ts_titleText: "Ofrecemos  un servicio de transporte único y profesional",

  //gallery
  ga_titleText: "¡Descubre lo maravilloso de Nicaragua con nosotros!",

  //Transportation Page
  VehicleFleet: "Flota Vehicular",
  FletQuote: "Operamos una flota moderna, limpia y en perfecto estado",
  ts_GetQuote:
    " <p>Nicaragua´s Best Guides ofrece servicio de transporte profesional para individuos o empresas a destinos en toda Nicaragua.</p><p>Ofrecemos una ventanilla única para todos sus grupos y necesidades. Deje que nuestro equipo de expertos le ayude a planificar su próximo viaje. Las consultas son gratuitas. Debajo detallamos algunos destinos en nuestro país:</p>",

  HyundaiH1:
    "1 a 10 asientos Minivans con control de clima y A/C, asientos normales, AM y FM y CD de audio.",
  ToyotaHiace:
    "8 a 10 asientos Minivans con control de clima y A/C, asientos normales, AM y FM y CD de audio.",
  ToyotaCoaster:
    "8 a 20 asientos Bus con control de clima y A/C, asientos normales, AM y FM y CD de audio.",
  MarcoPoloSenior:
    "10 a 24 asientos pequeños de lujo, compartimiento de almacenamiento espaciosas con control de clima y A / C. Asientos reclinables y reposabrazos, AM / FM y CD de audio, Altavoces / micrófono.",
  MarcoPoloScania:
    "10 a 50 asientos de lujo, compartimientos de almacenamiento espaciosas con control de clima y A / C, asientos reclinables y reposabrazos, AM / FM y CD, Altavoces / micrófono, sistema de vídeo (VHS o DVD) Y baños.",

  //Other Services Page
  OtherServices: "Otros Servicios",
  AirportWelcoming: "Aeropuerto, Bienvenida y Asistencia",
  BilingualGuide: "Servicio profesional de guías bilingües",
  CarRental: "Alquiler de Vehículos; Microbuses; Buses",
  CharterServices: "Operación de servicios Charter",
  CruisesHandling: "Operación de servicio a Cruceros",

  CustomizedItineraries: "Paquetes a la medida",
  DestinationManagement: "Representación en Destino",
  DomesticFlights: "Reservaciones y ventas de Vuelos Nacionales",
  HalfandFullTours: "Excursiones de Medio Día y Día Completo",
  GroupPrograms: "Programas para Grupos",

  HotelReservation: "Reservaciones de Hoteles",
  IncentivePrograms: "Programas de Incentivos",
  Multidestination: "Programas Multidestinos",
  OrganizationSeminars: "Organización de Seminarios y Convenciones",
  PrivateServices: "Traslados Privados",

  //Template Travels
  BackToDeals: "Volver a Promociones",
  BackTo: "Volver a ",
  Or: "o ",
  RelatedToursTitle: "Excursiones de este destino",
  RelatedToursQuote:
    " ¡Disfruta de paquetes especializados para que disfrutes de este destino con nosotros!",
}
