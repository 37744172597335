import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"

import Helmet from "react-helmet"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"

import "intl"

// import en from "react-intl/locale-data/en"
// import en from "intl/locale-data/jsonp/en"
// import es from "react-intl/"
// import es from "intl/locale-data/jsonp/es"

import { FormattedMessage } from "react-intl"
import lang from "../data/languages"

import "./styles/layout.scss"

import { ParallaxProvider } from "react-scroll-parallax"
import { TweenMax } from "gsap"

import * as PropTypes from "prop-types"

import MessengerCustomerChat from "react-messenger-customer-chat"

// add concatenated locale data
// addLocaleData([...en, ...es])

const Layout = props => {
  useEffect(() => {
    CursorAnimation()
  }, [])

  const CursorAnimation = () => {
    const cursor = document.querySelector(".best-cursor")
    const follower = document.querySelector(".follow")
    const _a = document.querySelectorAll("a")

    let posX = 0,
      posY = 0

    let mouseX = 0,
      mouseY = 0

    // console.log(cursor, follower, _a);

    TweenMax.to({}, 0.016, {
      repeat: -1,
      onRepeat: function() {
        posX += (mouseX - posX) / 9
        posY += (mouseY - posY) / 9

        TweenMax.set(follower, {
          css: {
            left: posX - 12,
            top: posY - 12,
          },
        })

        TweenMax.set(cursor, {
          css: {
            left: mouseX,
            top: mouseY,
          },
        })
      },
    })

    document.addEventListener("mousemove", e => {
      mouseX = e.pageX
      mouseY = e.pageY
    })
    _a.forEach(a => {
      a.addEventListener("mouseenter", () => {
        cursor.classList.add("active")
        follower.classList.add("active")
      })
    })

    _a.forEach(a => {
      a.addEventListener("mouseleave", () => {
        cursor.classList.remove("active")
        follower.classList.remove("active")
      })
    })
  }

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `)

  // children = props.children; const data = this.props.data;
  const { siteUrl } = data.site.siteMetadata
  const { location, children } = props
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey === lang.defaultLangKey ? "" : langKey}`
  // const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url));
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(
    item => ({
      ...item,
      link: item.link.replace(`/${defaultLangKey}/`, "/"),
    })
  )
  // get the appropriate message file based on langKey at the moment this assumes
  // that langKey will provide us with the appropriate language code
  const i18nMessages = require(`../data/messages/${langKey}`)

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <div>
        <Helmet
          htmlAttributes={{
            lang: langKey,
          }}
        >
          <meta
            name="google-site-verification"
            content="QGZpxNYYrGLMYoXhbQmmxonziDHyBEFNfczwKfIAFIA"
          />
          {/* {process.env.NODE_ENV === "production" && (
            <script src="//code.tidio.co/e1cdjd50tkoj1meldozgwktnybrt5ozp.js"></script>
          )} */}

          {langsMenu &&
            langsMenu.map((lang, index) => {
              const _siteUrl = `${siteUrl}${lang.link}`
              const _title = lang.langKey === `es` ? `Español` : `English (US)`
              return (
                <link
                  key={index}
                  rel="alternate"
                  type="text/html"
                  hreflang={lang.langKey.toLowerCase()}
                  href={_siteUrl.replace(/\/\/+/g, "/")}
                  title={_title}
                />
              )
            })}
        </Helmet>

        <Header langs={langsMenu} currentLang={langKey} />
        <ParallaxProvider>{children}</ParallaxProvider>
        <Footer currentLang={langKey} />
        <div className="best-cursor" />
        <div className="follow" />
      </div>

      <div>
        <MessengerCustomerChat pageId="452478454853692" theme_color="#3952a2" />
      </div>
    </IntlProvider>
  )
}

Layout.propTypes = {
  location: PropTypes.any.isRequired,
}

export default Layout
