import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import config from "../utils/config"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, keywords, title, image, packageImageUrl }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description
  const OpenImg = packageImageUrl
    ? packageImageUrl
    : `${site.siteMetadata.siteUrl}images/${image}`

  const jsonLD = input => ({
    "@context": "http://schema.org",
    "@id": `${config.SITE_URL}#${input}`,
    "@type": "Organization",
    address: {
      "@type": "PostalAddress",
      addressCountry: "NI",
      addressLocality: "",
      postalCode: "",
    },
    name: site.siteMetadata.title,
    alternateName: config.SITE_TITLE_ALT,
    description: metaDescription,
    url: config.SITE_URL,
    email: "info@nicaraguasbestguides.com",
    founder: "",
    foundingDate: "",
    foundingLocation: "Nicaragua",
    image: {
      "@type": "ImageObject",
      url: `${config.SITE_URL}${config.SITE_SEO_IMG}`,
      height: "512",
      width: "512",
    },
    logo: {
      "@type": "ImageObject",
      url: `${config.SITE_URL}${config.SITE_LOGO_IMG}`,
      height: "62",
      width: "130",
    },
    sameAs: ["https://www.facebook.com/graylinenicaragua"],
  })

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `og:image`,
          content: OpenImg,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(jsonLD("identity"))}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
  keywords: [],
  image: `default-seo.jpg`,
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
