module.exports = {
  selectLanguage: "Select your language",
  itinerario: "Itinerary",
  details: "Details",
  mainTitle: "Example of using Contentful as a data source for a Gatsby site",
  popularDestination: "Popular Destinations",

  Alternatives: "Alternatives",
  WeKnow: "We Know",
  WeAreText:
    "We are a Full-Service Tour Operator and Destination Management Company (DMC).",
  WeAreText2:
    "We offer tours in Nicaragua with competitive rates and a wide selection of products, allowing us to provide a first rate service to our customers.",
  AboutSubText: "We are more than an agency",
  ServicesSubText: "We have many services at your disposal",

  ViewMore: "View More",

  //ModalForm
  SendRequest: "Send a Request",
  SendMessage: "Send Request",
  PackageName: "Package Name",
  EmailAddres: "Email Adress",
  Comments: "Aditional Comments",

  //MenuItem
  Home: "Home",
  AboutUs: "About Us",
  Services: "Our Services",
  Guides: "Guides",
  Transportation: "Transportation",
  Gallery: "Photo Gallery",
  ContactUs: "Contact Us",
  FollowUs: "Follow Us",
  Deals: "Hot Deals",
  HotDeals: "Hot Deals",
  Tours: "Tours",
  //Index
  ViewDeals: "Hot Deals",
  DealsPhrase: "We have the best Deals in Nicaragua",
  From: "From",
  Now: "Now",
  EnjoyPhrase: "¡Discover the most popular attractions in the country with us!",
  viewAll: "View All Destinations",
  WhyChooseUs: "Why Choose",

  WhyPhrase: "travel insurance with the support you need",
  Assistance: "24/7 Assistance",
  Departures: "Guaranteed Departures",
  Environmentally: "We are an environmentally responsible company",
  Quality: "Quality in our Services",
  Nofee: "No fee to change dates",
  Handpicked: "Handpicked tours & experiences",

  Recommends: "Recommends",

  //footer
  ProudMember: "Proud Members Of",
  Office: "Office",
  "24HourAttention": "24-Hour Attention:",

  //About Page
  LeadingTour: "Leading tour operator in Nicaragua",
  WhoWeAre: "Who We Are?",
  WhoWeAreContent:
    "<p>We are proud to introduce to you Nicaragua’s Best Guides. Our company offers full service accommodations, transfers, tours, as well as organizing conferences and incentive travel (DMC). Nicaragua’s Best Guides is known for delivering professional, quality and personalized attention to our clients 24 hours. We have competitive rates and a wide selection of products, allowing us to provide a first class service to our customers. </p><p> Licensed as a tour operator and approved by the Nicaraguan Institute of Tourism (INTUR), our name has been a synonym of development and implementation of new products in the country, as well as corporate responsibility to support conservation programs and environmental protection driven by Rain Forest Alliance and the Natura Fund. </p><p> Our modern fleet of buses offers private tours and ground transportation services to major tourist attractions in the country. Our main office is located in the colonial city Granada. </p>",
  HappyCustomers: "Happy customers",
  ToursAndPackages: "Tours & Packages",
  ExperienceYears: "Years of experience",
  OurMission: "Our Mission",
  OurMissionTitle: "We are a receptive tour company that provides",
  OurMissionDesc:
    "comprehensive travel services which are distinguished in the market for its high competitiveness, performance, quality, excellence and commitment to the sustainable development of the country. We support continued innovation, training and incorporating technology to satisfy domestic and foreign tourists.",
  OurVision: "Our Vision",
  OurVisionTitle: "We strive to be a full-service tour operator",
  OurVisionDesc:
    "that always responds to market trends, and to have national and international recognition with highly qualified personnel. We try to be a trader using the latest technology and offering innovative, competitive and high quality that meets the expectations of our clients within a framework of sustainable tourism.",

  //Events Page
  Events: "Events",
  ts_Weddings:
    " <p>Before you walk down the aisle, you get one last chance to celebrate your single-hood with your closest friends and family. Why not celebrate onboard a Nicaragua´s Best Guides transportation?</p><p>We can also provide fun and reliable transportation for rehearsal dinners, receptions, etc. If you are having lots of out of town guests in for your wedding, what better way to transport them around the city than in a Nicaragua´s Best Guides&nbsp;transportation? Our unique transportation will provide an even more memorable day for you and your guests. Contact us for more information.</p>",
  ts_Events:
    " <p>Planning a product launch, meeting, outing, training session, promotional event, or conference? Whether you need to move and entertain 10 or 500 people let Nicaragua´s Best Guides take the pressure off with our professional service. Give your guests, especially those from out of town, the opportunity to experience the city. </p><p>And if you are organizing a convention in Nicaragua we may be able to arrange for your group all the transportation they may need. Contact us for more information.</p>",
  ts_meetings:
    " <p>A special birthday, anniversary, or annual tradition. Regardless of your reason for having family or friends together for a reunion, come to Nicaragua´s Best Guides for professional, courteous, and fun transportation service in and around Nicaragua.</p><p>If you and your family or friends would like to spend time traveling across Nicaragua together, consider organizing a custom group tour. We can arrange accommodations, attractions, etc. for your entire group. Contact us for more information.</p>",
  ts_Colleges:
    "<p>Turn to Nicaragua´s Best Guides if your school group needs reliable and fun transportation between destinations in and around Nicaragua. Our group sales department can also provide educational tours of Nicaragua for your class. We have experienced providing group transportation services for athletic teams, band trips, clubs, classroom field trips, and so much more. Contact us for more information.</p>",
  ts_titleText: "We offers a unique and professional transportation service",

  //gallery
  ga_titleText: "Learn about the wonderfulness of Nicaragua with us",

  //Transportation
  VehicleFleet: "Vehicle Fleet",
  FletQuote:
    "We operate a modern, clean, and in perfect state of the art fleet",
  ts_GetQuote:
    "<p>Nicaragua´s Best Guides offers a unique and professional transportation service to entertain your clients or guests as they tour Nicaragua or travel between business and social destinations in all Nicaragua.</p><p>We offer one stop shopping for all of your groups and needs! Let our team of experts help plan your next trip. Charter quotes are always free. Select the most appropriate departure area from the list at the left to get started. Contact us for more information.</p>",
  HyundaiH1:
    " Minivans 1-9 seats with climate control with A/C, standard and reclining seats, AM/FM and CD audio system.",
  ToyotaHiace:
    " 8 to10 Seats Minivans with climate control with A/C, standard seats, AM/FM and CD audio system.",
  ToyotaCoaster:
    " 10 to 20 Seats Bus with climate control with A/C, standard seats, AM/FM and CD audio system.",
  MarcoPoloSenior:
    " 10 to 24 Seats Small luxuries Motorcoach Bus, Spacious Storage Compartments with climate control with A/C contoured reclining seats and armrest, AM/FM and CD audio system, PA / microphone system.",
  MarcoPoloScania:
    " 10 to 50 Seats luxuries Motorcoach Bus, Spacious Storage Compartments with climate control with A/C contoured reclining seats and armrest, AM/FM and CD audio system, PA / microphone system, Video system (VHS or DVD, Lavatory.",

  //Other Services Page
  OtherServices: "Other Services",
  AirportWelcoming: "Airport Welcoming and Assistance",
  BilingualGuide: "Bilingual Guide Service",
  CarRental: "Car & Bus Rental",
  CharterServices: "Charter Operation’s Services",
  CruisesHandling: "Cruises Handling Passenger Services",

  CustomizedItineraries: "Customized Itineraries",
  DestinationManagement: "Destination Management Service",
  DomesticFlights: "Domestic Flights Reservation",
  HalfandFullTours: "Half & Full Day Tours",
  GroupPrograms: "Group Programs",

  HotelReservation: "Hotel Reservation",
  IncentivePrograms: "Incentive Programs",
  Multidestination: "Multidestination programs",
  OrganizationSeminars: "Organization of Conventions and Seminars",
  PrivateServices: "Private Transfer Services",

  //Template Travels
  BackToDeals: "Back To Hot Deals",
  BackTo: "Back to ",
  Or: "or ",
  RelatedToursTitle: "Tours of this destination",
  RelatedToursQuote:
    "Enjoy specialized packages to enjoy this destination with us",
}
