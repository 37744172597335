import React from "react"
// import {useStaticQuery} from 'gatsby'
// import Img from 'gatsby-image';

const PagesHero = ({ children }) => {
  // const data = useStaticQuery(graphql `
  //     query {
  //         hero: file(name: {eq: "hero-header"}) {
  //             childImageSharp {
  //                 fluid(maxWidth:1920) {
  //                     src
  //                     ...GatsbyImageSharpFluid_noBase64
  //                 }
  //             }
  //         }}`)
  return (
    <React.Fragment>
      {/* <Img fluid={data.hero.childImageSharp.fluid}></Img> */}
      <section className="hero is-primary is-bold">
        <div
          className="hero-body"
          style={{
            padding: `4rem`,
          }}
        />
      </section>
      <div className={`__pages-content`}>{children}</div>
    </React.Fragment>
  )
}

export default PagesHero
